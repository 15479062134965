import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './AppMint';
import AppWalletconnect from './AppWalletconnect';
import reportWebVitals from './reportWebVitals';
// import PageBody from './Preview';
import './App.css';

// ReactDOM.render(
//   <React.StrictMode>
//     <PageBody />
//   </React.StrictMode>,
//   document.getElementById('root')
// );


ReactDOM.render(
  <React.StrictMode>
    <AppWalletconnect />
  </React.StrictMode>,
  document.getElementById('myBtn')
);

// document.getElementById('mintnowbuttonid').onclick = function x(){


//   ReactDOM.render(
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>,
//     document.getElementById('readytomintcolumnid')
//   );

// };
  




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
